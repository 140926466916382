// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './weekendcom.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/Weekendcom/Weekendcom.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage src="../../../images/casestudy/weekendcom/heroimg.png" alt="" objectFit="contain" className="image" />
)
const welcomeImg = (
  <StaticImage src="../../../images/casestudy/weekendcom/welcome.png" alt="" objectFit="contain" className="image" />
)
const infoImg = (
  <StaticImage src="../../../images/casestudy/weekendcom/award.png" alt="" objectFit="contain" className="image" />
)
const func1Img = (
  <StaticImage src="../../../images/casestudy/weekendcom/func1.png" alt="" objectFit="contain" className="image" />
)
const func2Img = (
  <StaticImage src="../../../images/casestudy/weekendcom/func2.png" alt="" objectFit="contain" className="image" />
)
const brandingImg = (
  <StaticImage src="../../../images/casestudy/weekendcom/Logo.svg" alt="" className="image image-brand" />
)
const colorsImg = (
  <StaticImage src="../../../images/casestudy/weekendcom/Colors.png" alt="" objectFit="contain" className="image" />
)
const recognitionImg = (
  <StaticImage
    src="../../../images/casestudy/weekendcom/recognition.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/weekendcom/tech.png" alt="" objectFit="contain" className="image" />
)
const feedbackBoesse = (
  <StaticImage
    src="../../../images/casestudy/weekendcom/client-boese.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback01 = (
  <StaticImage
    src="../../../images/casestudy/weekendcom/customer-01.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback02 = (
  <StaticImage
    src="../../../images/casestudy/weekendcom/customer-02.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback03 = (
  <StaticImage
    src="../../../images/casestudy/weekendcom/customer-03.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

// import appstoreImgSrc from '../../../images/casestudy/weekendcom/app-store.svg'
// const appstoreImg = <img src={appstoreImgSrc} alt='' className='image' />
// import googleplayImgSrc from '../../../images/casestudy/weekendcom/google-play.svg'
// const googleplayImg = <img src={googleplayImgSrc} alt='' className='image' />
import welcomeLogoSrc from '../../../images/casestudy/weekendcom/Logo.svg'
const welcomeLogo = <img src={welcomeLogoSrc} alt="" className="image" />
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  // link: 'https://www.weekend.com/',
  name: 'weekend.com',
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.weekend.title,
    description: projectsList.weekend.description // ,
    // appstoreImg: appstoreImg,
    // googleplayImg: googleplayImg,
    // appstore: true
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  info: {
    img: infoImg,
    title: caseMessages.infoTitle,
    list: [caseMessages.infoItem1, caseMessages.infoItem2]
  },
  stats: [
    {
      number: 600000,
      positive: true,
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-download'
    },
    {
      number: 6,
      numberDimension: dimensionMessages.dimensionCountries,
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-user'
    },
    {
      number: 400,
      positive: true,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-beach-access'
    },
    {
      number: 30000,
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-house'
    }
  ],
  functionalitiesTitle: baseCaseMessages.keyFunctionsTitle,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [caseMessages.functionality1Item1, caseMessages.functionality1Item2, caseMessages.functionality1Item3],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [caseMessages.functionality2Item1, caseMessages.functionality2Item2],
      img: func2Img
    }
  ],
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: baseCaseMessages.brandingDoneTitle,
    logo: {
      title: baseCaseMessages.logoTitle,
      list: [caseMessages.brandLogoItem1, caseMessages.brandLogoItem2, caseMessages.brandLogoItem3],
      img: brandingImg
    },
    colors: {
      title: baseCaseMessages.colorsTitle,
      list: [caseMessages.brandColorsItem1, caseMessages.brandColorsItem2, caseMessages.brandColorsItem3],
      img: colorsImg
    },
    recognition: {
      title: baseCaseMessages.recognitionTitle,
      text: caseMessages.brandRecognitionText,
      img: recognitionImg
    },
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.weekend.overviewImage
    }
  },
  feedback: [
    {
      text: caseMessages.feedbackText,
      from: caseMessages.feedbackFrom,
      img: feedbackBoesse
    }
  ],
  reviews: [
    {
      img: feedback01,
      name: 'Alexandra N.K.',
      from: 'Google App Store',
      title: 'Great Escapes!',
      rating: [1, 2, 3, 4, 5],
      text: 'For unplanned small vacations that meet your expectations.'
    },
    {
      img: feedback02,
      name: 'Julia P.',
      from: 'Google App Store',
      title: 'The best travel app!',
      rating: [1, 2, 3, 4, 5],
      text: 'Time saver and best finder for great weekend travelers.'
    },
    {
      img: feedback03,
      name: 'Jens Hackhänder',
      from: 'Google App Store',
      title: 'Excellent!',
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      text: 'There is the right one for every requirement. Clearly arranged. I recommend the app with a clear conscience.'
    }
  ] // ,
  // check: {
  //   img: projectsList.weekend.marketingImage,
  //   title: baseCaseMessages.checkTitle,
  //   text: baseCaseMessages.checkText
  // }
}

const CaseWeekendcom = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyWeekend)}
        description={formatMessage(metaDescription.CaseStudyWeekendDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseWeekendcom)
